import React, { useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { destroyCookie } from "nookies";

import Image from "next/image";

import {
  AppBar,
  Toolbar,
  Container,
  Box,
  IconButton,
  Link as MuiLink,
  Drawer,
  List,
  ListItem,
  Button,
  Grid,
} from "@mui/material";

import * as ga from "@lib/ga";

import useAuthAction from "../Auth/useAuthAction";
import { AuthDialogContext } from "../../../contexts/AuthDialogContext";
import { UserDataContext } from "../../../contexts/UserDataContext";

import { logout } from "@operations/auth";
// import CartIcon from "../CartIcon";
// import { CartCountContext } from "../../../contexts/CartContext";
// import { GET_CART_COUNT } from "@operations/cart/queries";
import { useQuery } from "@apollo/client";
import AccountMenu from "@components/common/AccountMenu";
import styled from "@emotion/styled";
import MUITooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { colors } from "@theme/colors";
import OrgAccountMenu from "@components/OrgProfile/OrgAccountMenu";
import { GET_USER_ORG } from "../../../operations/user/queries";

const Tooltip = styled(({ className, ...props }) => (
  <MUITooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: colors.background.default,
    color: "rgba(0, 0, 0, 0.87)",
    //
    marginTop: "0 !important",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    // minWidth: "25vw",
    padding: "1rem",
    maxWidth: "30vw",
  },
}));

const styles = {
  menuItem: {
    fontSize: "18px",
    lineHeight: "12px",
    fontWeight: "600",
    textDecoration: "none",
    margin: "0.5rem",
    color: "primary.purple",
    opacity: 1,
    borderRadius: 0,
    "&:hover": {
      borderBottom: "3px solid black",
    },
  },
  active: {
    color: "primary.purple",
    borderBottom: "3px solid black",
    "&:hover": {
      borderBottom: "3px solid black",
    },
  },
  popoverLinks: {
    fontSize: "18px",
    lineHeight: "28px",
    fontWeight: "600",
    textDecoration: "none",
    margin: "0.5rem",
    color: "primary.purple",
    padding: "0.5rem",
    opacity: 1,
    // width: "100%",
    "&:hover": {
      color: "#69486c",
    },
  },
};

const NavBar = () => {
  const { t } = useTranslation("common");

  const router = useRouter();
  const withAuthAction = useAuthAction();
  const { setAuthDialog } = useContext(AuthDialogContext);
  // const { cartCount, updateCartCount } = useContext(CartCountContext);
  const { authUser, userData } = useContext(UserDataContext);
  const [anchorElNav, setAnchorElNav] = useState(false);
  const [logo, setLogo] = useState("");

  // const [getCartCount] = useLazyQuery(GET_CART_COUNT, {
  //   onCompleted: (data) => {
  //     if (!isNaN(data.cartById?.no_of_items))
  //       updateCartCount(data.cartById.no_of_items);
  //   },
  // });

  const { data: { userOrg: orgData } = {} } = useQuery(GET_USER_ORG, {
    fetchPolicy: "network-only",
  });

  // useEffect(() => {
  //   const cartId = localStorage.getItem("cartId");
  //   if (cartId) getCartCount({ variables: { id: parseInt(cartId) } });
  // }, []);

  useEffect(() => {
    setLogo(`/assets/images/clients/${orgData?.logo}`);
  }, [orgData]);

  const handleLogout = async () => {
    await logout();
    destroyCookie(null, "token");
    setAuthDialog(false);
    router.push("/login"); // redirecting to login
  };

  const handleOpenNavMenu = () => {
    setAnchorElNav(true);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(false);
  };

  const primaryNav = [
    {
      label: t("home"),
      path: "/",
      key: "home",
    },
    {
      label: t("platform"),
      path: "/platform",
      key: "platform",
    },
    // {
    //   label: t("our_value"),
    //   path: "/our_value",
    //   key: "value",
    // },
    {
      label: t("solutions"),
      path: "/solutions",
      key: "solutions",
    },
    // {
    //   label: t("case_studies"),
    //   path: "/case-studies",
    //   key: "case_studies",
    // },
    // {
    //   label: t("blog"),
    //   path: "/blog",
    //   key: "blog",
    // },
    // {
    //   label: t("pricing"),
    //   path: "/pricing",
    //   key: "pricing",
    // },
  ];

  const orgVerticalNav = [
    {
      label: t("my_profile"),
      path: "/org/profile",
    },
    {
      label: t("dashboard"),
      path: `/org/dashboard?spaceId=${orgData?.spaceId}`,
      disabled: false,
    },
    // {
    //   label: t("digital_garden"),
    //   path: "/org/plants",
    //   disabled: false,
    // },
    // {
    //   label: t("maintenance"),
    //   path: "/org/maintenance",
    //   disabled: true,
    // },
    // {
    //   label: t("org_wellness"),
    //   path: "/org/wellness",
    //   disabled: true,
    // },
    // {
    //   label: t("rewards"),
    //   path: "/org/rewards",
    //   disabled: true,
    // },
    // {
    //   label: t("plan"),
    //   path: "/org/plan",
    //   disabled: false,
    // },
    {
      label: t("files"),
      path: "/org/files",
      disabled: false,
    },
  ];

  return (
    <>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          bgcolor: authUser && userData?.org_id && colors.primary.linen,
        }}
      >
        <Container maxWidth="100%">
          <Toolbar
            disableGutters={true}
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "auto 1fr", sm: "auto 1fr auto" },
            }}
          >
            <Box
              sx={{
                ml: "-8px",
              }}
            >
              <MuiLink
                component={Button}
                href="/"
                underline="none"
                sx={{
                  opacity: 1,
                }}
              >
                <Image
                  src="/assets/images/naturethings_logo.svg"
                  width="173"
                  height="50"
                  layout="fixed"
                  alt={t("nature_things")}
                  className="pointer"
                />
              </MuiLink>
            </Box>

            {/* ------ mobile menu------- */}
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", lg: "none" },
                justifyContent: "flex-end",
              }}
            >
              {/* <MuiLink component={Button} href="/cart" passhref>
                <IconButton variant="curved" color="info" sx={{ top: "-5px" }}>
                  <CartIcon count={cartCount} />
                </IconButton>
              </MuiLink> */}
              <IconButton
                aria-label="get nature things menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <Image
                  src="/assets/images/icon-menu.png"
                  width="44px"
                  height="18px"
                  layout="fixed"
                  alt="menu"
                />
              </IconButton>
              <Drawer
                anchor="right"
                open={anchorElNav}
                onClose={handleCloseNavMenu}
                PaperProps={{
                  sx: { width: { xs: "60%", sm: "40%", md: "30%" }, pt: 2 },
                }}
              >
                <List>
                  {primaryNav.map((item, index) => (
                    <ListItem
                      key={`primaryNav-${index}`}
                      sx={{ justifyContent: "center" }}
                    >
                      <MuiLink
                        sx={{
                          ...styles.menuItem,
                          ...(item.path === router.pathname && styles.active),
                        }}
                        href={item.path}
                        component={Button}
                        underline="none"
                      >
                        {item.label}
                      </MuiLink>
                    </ListItem>
                  ))}

                  {/* <ListItem sx={{ justifyContent: "center" }}>
                    <MuiLink
                      sx={{
                        ...styles.menuItem,
                        ...("/cart" === router.pathname && styles.active),
                      }}
                      href="/cart"
                      component={Button}
                      underline="none"
                    >
                      {t("Cart")}
                    </MuiLink>
                  </ListItem> */}

                  {authUser &&
                    userData?.org_id &&
                    orgVerticalNav.map((item, index) => (
                      <ListItem
                        key={`verticalNav-${index}`}
                        sx={{ justifyContent: "center" }}
                      >
                        <MuiLink
                          sx={{
                            ...styles.menuItem,
                            ...(item.path === router.pathname && styles.active),
                          }}
                          href={item.path}
                          component={Button}
                          underline="none"
                          disabled={item.disabled}
                        >
                          {item.label}
                        </MuiLink>
                      </ListItem>
                    ))}

                  <ListItem
                    sx={{
                      justifyContent: "center",
                      display: { xs: "flex", md: "none" },
                    }}
                  >
                    {/* <AccountMenu /> */}
                    <Grid container item mt={2} justifyContent="center">
                      {!authUser ? (
                        <Button
                          variant="containedGreen"
                          onClick={() => {
                            withAuthAction();
                            ga.event({ action: "login header" });
                          }}
                        >
                          {t("login")}
                        </Button>
                      ) : (
                        <Button
                          sx={{
                            color: "#df4141",
                            textAlign: "center",
                          }}
                          variant="contained"
                          onClick={handleLogout}
                          color="inherit"
                        >
                          {t("logout")}
                        </Button>
                      )}
                    </Grid>
                  </ListItem>
                </List>
              </Drawer>
            </Box>

            {/* ------------- home header ---------- */}
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", lg: "flex" },
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {primaryNav.map((item, index) => {
                if (item.popover) {
                  return (
                    <Tooltip
                      title={
                        <React.Fragment>
                          <Grid container>
                            {item.popover?.map((shopFilter, index) => (
                              <Grid
                                item
                                xs={8}
                                key={index}
                                sx={{
                                  margin: "0.5rem 0",
                                }}
                              >
                                <MuiLink
                                  href={shopFilter.query}
                                  sx={{
                                    ...styles.popoverLinks,
                                  }}
                                >
                                  {shopFilter.label}
                                </MuiLink>
                              </Grid>
                            ))}
                          </Grid>
                        </React.Fragment>
                      }
                    >
                      <MuiLink
                        sx={{
                          ...styles.menuItem,
                          ...(item.path === router.pathname && styles.active),
                        }}
                        href={item.path}
                        key={index}
                        component={Button}
                        underline="none"
                      >
                        {item.label}
                      </MuiLink>
                    </Tooltip>
                  );
                }

                return (
                  <MuiLink
                    sx={{
                      ...styles.menuItem,
                      ...(item.path === router.pathname && styles.active),
                    }}
                    href={item.path}
                    key={index}
                    component={Button}
                    underline="none"
                  >
                    {item.label}
                  </MuiLink>
                );
              })}
              {/* <MuiLink href="/cart" component={Button} underline="none">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <IconButton
                    variant="curved"
                    color="info"
                    // sx={{ top: "-5px" }}
                  >
                    <CartIcon count={cartCount} />
                  </IconButton>
                </Box>
              </MuiLink> */}
              <Box sx={{ flexGrow: 0, display: { xs: "none", lg: "block" } }}>
                {!authUser ? (
                  <Button
                    variant="containedGreen"
                    onClick={() => {
                      withAuthAction();
                      ga.event({ action: "login header" });
                    }}
                  >
                    {t("login")}
                  </Button>
                ) : authUser && userData?.org_id ? (
                  <OrgAccountMenu setAuthDialog={setAuthDialog} />
                ) : (
                  <AccountMenu setAuthDialog={setAuthDialog} />
                )}
              </Box>
            </Box>

            {/* ------------- add company logo  --------------*/}
            {authUser && orgData && (
              <Box
                sx={{
                  ml: 10,
                  marginTop: "5px",
                  marginBottom: "5px",
                  backgroundColor: "white",
                  borderRadius: 2,
                  height: "50px",
                  width: "50px",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <Image
                  src={logo}
                  alt={t("nature_things")}
                  className="pointer"
                  layout="fill"
                  objectFit="contain"
                />
              </Box>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default NavBar;
